import { useState, useEffect } from "react";
import { differenceInDays } from "date-fns";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useAddToCartWithLuxe } from "../../context/LuxeProvider";
import { resizeShopifyImage } from "../../context/helpers";
import { useProductData2 } from "../../context/products";
import { useCurrentCountryCode } from "../../hooks/usePrices";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";

import { ALButton } from "../ALComponents";
import GiftBoxPrice from "./giftBoxUpsell/GiftBoxPrice";
import GiftIcon from "../icons/svgs/gift.svg";
import CloseIcon from "../icons/svgs/close-thick.svg";

import * as Styles from "./giftBoxUpsell/style.module.scss";

const GIFT_BOX_HANDLE = "ana-luisa-jewelry-box";
const PRODUCT_DATA_HANDLES = [GIFT_BOX_HANDLE];
const GIFT_BOX_POUP_DISMISSED_KEY = "gift-box-popup-dismissed";

function GiftBoxUpsell() {
  const addToCartWithLuxe = useAddToCartWithLuxe();
  const countryCode = useCurrentCountryCode();
  const { data: giftBoxProduct } = useProductData2(PRODUCT_DATA_HANDLES, "long", countryCode);
  const dict = useLocalizedSentenceDict();

  const [isVisible, setIsVisible] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const product = giftBoxProduct && giftBoxProduct[0] ? { ...giftBoxProduct[0].shopify } : null;

  useEffect(() => {
    const dismissedDate = localStorage.getItem(GIFT_BOX_POUP_DISMISSED_KEY);
    if (dismissedDate) {
      const daysDiff = differenceInDays(new Date(), new Date(dismissedDate));
      // If the dismissed time is in the last day, do not show the gift box upsell
      if (daysDiff === 0) {
        setIsVisible(false);
      }
    }
  }, []);

  const hideUpsell = () => {
    // Clicking on the close button when the widget is expanded will toggle to collapsed state
    if (isExpanded) {
      setIsExpanded(false);
    } else {
      setIsVisible(false);

      // store the date when the user closed the upsell
      localStorage.setItem(GIFT_BOX_POUP_DISMISSED_KEY, new Date());
    }
  };

  const toggleExpandUpsell = () => {
    setIsExpanded(!isExpanded);
  };

  const addToCart = async () => {
    const productVariant = product.variants[0];
    const dataProductToAdd = {
      quantity: 1,
      attributes: [],
      variant: {
        id: productVariant.id,
        title: giftBoxProduct.title,
        sku: giftBoxProduct.sku,
        product: {
          id: product.id,
          handle: product.handle,
          productType: product.productType,
        },
      },
    };

    await addToCartWithLuxe({ dataProductsToAdd: [dataProductToAdd] });
    setIsVisible(false);
  };

  if (!isVisible || !product) {
    return null;
  }

  return (
    <div
      className={`giftbox-upsell ${Styles.wrapper} ${isExpanded ? Styles.wrapperExpanded : ""}`}
      onClick={toggleExpandUpsell}
    >
      <button type="button" onClick={hideUpsell} className={Styles.closeBtn} data-testid="close">
        <CloseIcon width="12" fill="#000" stroke="#000" />
      </button>

      <div className={Styles.container}>
        {isExpanded ? (
          <div className={Styles.contentExpanded}>
            <img
              src={resizeShopifyImage(product.images[0]?.url, "x240")}
              alt={product.images[0]?.altText}
            />
            <div className={Styles.info}>
              <div className={Styles.infoMain}>
                <div className="subtext subtext--bold subtext--uc">{dict.get("GIFT BOX")}</div>
                <GiftBoxPrice inline={false} productHandle={GIFT_BOX_HANDLE} />
              </div>
              <ALButton variant="secondary" size="small" onClick={addToCart}>
                {dict.get("ADD TO BAG")}
              </ALButton>
            </div>
          </div>
        ) : (
          <div className={Styles.content}>
            <GiftIcon width="17" fill="#c76c61" stroke="#FFFFFF" />
            <div className={`${Styles.text} subtext`}>
              <span>{dict.get("Add a gift box for")}&nbsp;</span>
              <GiftBoxPrice inline productHandle={GIFT_BOX_HANDLE} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withALErrorBoundary({
  name: "GiftBoxUpsell",
  priority: "P3",
})(GiftBoxUpsell);

import { useState } from "react";
import { PropTypes } from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { Link } from "../al_components/ALLink";

import * as seoPLPLinksStyles from "./seoPLPLinksStyles.module.scss";

function SEOPLPLinks({ seoCollectionLinks, breadcrumb }) {
  const [isOpen, setIsOpen] = useState(false);

  if (
    (seoCollectionLinks && seoCollectionLinks.length === 0) ||
    !breadcrumb ||
    breadcrumb?.levels.length === 0
  )
    return null;

  return (
    <div
      className={`${seoPLPLinksStyles.container} ${isOpen && seoPLPLinksStyles.container_more}`}
      style={{ color: "#898786" }}
    >
      {seoCollectionLinks.map(([title, { link }], i) => (
        <>
          <Link
            className="microtext"
            key={`seo-link-${link.replace("/collections/", "")}`}
            to={link}
          >
            {title}
          </Link>
          {i < seoCollectionLinks.length - 1 && " - "}
        </>
      ))}
      {isOpen ? (
        <button className="microtext" type="button" onClick={() => setIsOpen(false)}>
          Show Less
        </button>
      ) : (
        <button className="microtext" type="button" onClick={() => setIsOpen(true)}>
          ... Show More
        </button>
      )}
    </div>
  );
}

SEOPLPLinks.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  seoCollectionLinks: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  breadcrumb: PropTypes.object,
};

export default withALErrorBoundary({
  name: "SEOPLPLinks",
  priority: "P3",
})(SEOPLPLinks);

// extracted by mini-css-extract-plugin
export var awesome = "seoPLPLinksStyles-module--awesome--8405a";
export var bold = "seoPLPLinksStyles-module--bold--ae6e1";
export var center = "seoPLPLinksStyles-module--center--feb37";
export var container = "seoPLPLinksStyles-module--container--c43ee";
export var container_more = "seoPLPLinksStyles-module--container_more--ba0e1";
export var h1 = "seoPLPLinksStyles-module--h1--43945";
export var h1Bold = "seoPLPLinksStyles-module--h1--bold--4fc6c";
export var h1Uc = "seoPLPLinksStyles-module--h1--uc--f99e0";
export var h2 = "seoPLPLinksStyles-module--h2--66efd";
export var h2Bold = "seoPLPLinksStyles-module--h2--bold--1c558";
export var h2Uc = "seoPLPLinksStyles-module--h2--uc--67bea";
export var h3 = "seoPLPLinksStyles-module--h3--9b5f9";
export var h3Bold = "seoPLPLinksStyles-module--h3--bold--8f599";
export var h3Uc = "seoPLPLinksStyles-module--h3--uc--ca111";
export var h4 = "seoPLPLinksStyles-module--h4--b1b33";
export var h4Bold = "seoPLPLinksStyles-module--h4--bold--86b02";
export var h4Uc = "seoPLPLinksStyles-module--h4--uc--8fd85";
export var linkUnderline = "seoPLPLinksStyles-module--link--underline--e341b";
export var microtext = "seoPLPLinksStyles-module--microtext--982c6";
export var microtextBold = "seoPLPLinksStyles-module--microtext--bold--6c7df";
export var microtextUc = "seoPLPLinksStyles-module--microtext--uc--1d34c";
export var strikethrough = "seoPLPLinksStyles-module--strikethrough--9f84e";
export var strikethroughLg = "seoPLPLinksStyles-module--strikethrough--lg--040e6";
export var strikethroughSm = "seoPLPLinksStyles-module--strikethrough--sm--985b2";
export var subtext = "seoPLPLinksStyles-module--subtext--ac5b8";
export var subtextBold = "seoPLPLinksStyles-module--subtext--bold--05796";
export var subtextUc = "seoPLPLinksStyles-module--subtext--uc--9e94d";
export var text = "seoPLPLinksStyles-module--text--c8622";
export var textBold = "seoPLPLinksStyles-module--text--bold--455fc";
export var textUc = "seoPLPLinksStyles-module--text--uc--970ab";
export var titleL = "seoPLPLinksStyles-module--title-l--8e409";
export var titleM = "seoPLPLinksStyles-module--title-m--6061c";
export var titleS = "seoPLPLinksStyles-module--title-s--e5726";
export var titleXl = "seoPLPLinksStyles-module--title-xl--ee078";
export var titleXs = "seoPLPLinksStyles-module--title-xs--f34f9";
export var titleXxs = "seoPLPLinksStyles-module--title-xxs--20976";
export var uc = "seoPLPLinksStyles-module--uc--f047e";
export var underline = "seoPLPLinksStyles-module--underline--d7b2d";